
import { AvButton } from "@/components";
import { IOption } from "@core/services/interfaces/IUtil";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Ref } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import vSelect from "vue-select";

@Component({
  name: "DealershipsFilters",
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    AvButton,
  },
})
export default class DealershipsFilters extends Vue {
  @Prop({ required: true }) areaOptions!: IOption[];
  @Prop({ required: true }) groupOptions!: IOption[];
  @Prop({ required: true }) regionOptions!: IOption[];
  @Prop({ required: true }) stateOptions!: IOption[];
  @Prop({ required: true }) cityOptions!: [];
  @Prop({ required: true }) matrizOptions!: [];
  @Prop({ required: true }) associadaOptions!: [];
  @Prop({ required: true }) financeOptions!: [];
  @Prop({ required: true }) statusOptions!: [];
  @Prop({ required: true }) newOptions!: [];
  @Prop({ required: true }) brandOptions!: [];
  @Prop({ required: true }) groupFilter!: Ref<number>;
  @Prop({ required: true }) areaFilter!: Ref<number>;
  @Prop({ required: true }) regionFilter!: Ref<string>;
  @Prop({ required: true }) stateFilter!: Ref<string>;
  @Prop({ required: true }) cityFilter!: Ref<number>;
  @Prop({ required: true }) matrizFilter!: Ref<boolean | null>;
  @Prop({ required: true }) associadaFilter!: Ref<boolean | null>;
  @Prop({ required: true }) financeFilter!: Ref<boolean | null>;
  @Prop({ required: true }) statusFilter!: Ref<boolean | null>;
  @Prop({ required: true }) newFilter!: Ref<boolean | null>;
  @Prop({ required: true }) brandFilter!: Ref<boolean | null>;

  groupTitle = this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE;

  reduceValue(val: { label: string; value: any }) {
    return val.value;
  }

  updateGroupFilter(val: number) {
    this.groupFilter.value = val;
    this.$emit("update:group-filter", this.groupFilter);
  }

  clearFilters() {
    this.$emit("clearFilters");
  }
}
